<template>
  <base-modal wide :title="`Predefined Values for Field: ${currentField.label}`" :close-button="false" :overlay-closes="false">
    <div class="columns">
      <div class="column is-4" >
        <text-input placeholder="Enter Predefined Value" v-model="option.value">Add New Predefined Value</text-input>
      </div>
      <div v-if="currentField.linked_field" class="column is-4">
        <data-selector
            class="is-fullwidth"
            style="min-width: 80%"
            :items="linkedCustomFieldValues"
            hint="Show option when the linked custom field has this value selected"
            v-model="option.linked_value">Show this value for:
        </data-selector>
      </div>
      <div class="column is-2 is-flex align-items-end justify-start">
        <action-button @click="addValue" left-icon="plus-circle" :working="working" class="is-rounded-sml is-primary">
          Add
        </action-button>
      </div>
    </div>
    <div v-if="missingParentOptions && missingParentOptions.length" class="notification rounded-sml has-border-thin-grey">
        <div class="is-flex">
          <icon icon="exclamation-circle" class="has-text-danger"/>
          <p class="has-text-weight-normal pl-025">
            {{currentField.label}} is linked to {{linkedCustomField.label}}.
            Every <strong>Linked Value</strong> from {{linkedCustomField.label}} must be assigned to at least one <strong>Predefined Value</strong>.<br>
            Please assign these linked values:<br>
            <strong v-for="option in missingParentOptions" >{{ option.value }}<br></strong>
          </p>
        </div>

    </div>

    <div v-if="hasOptions" class="pl-1 pb-025 grid gap-1 has-12-columns is-uppercase tracking-wide has-text-grey" >
      <p></p>
      <p class="is-5-columns">Predefined Value <span class="has-text-danger has-text-weight-bold">*</span></p>
      <p class="is-5-columns" v-if="currentField.linked_field">Linked Value <span class="has-text-danger has-text-weight-bold">*</span></p>
      <p class="is-5-columns" v-else >Default Value</p>
    </div>
    <draggable :list="options" handle="#handle" :animation="200">
        <div v-for="(option, index) in options"
            :key="index"
            style="margin-bottom: 0.2rem"
            class="p-1 rounded-sml has-background-white-bis">
          <div class="grid gap-1 has-12-columns">
            <div class="is-flex align-items-center justify-start grab-cursor">
              <icon class="has-text-grey is-size-6" icon="grip-vertical" id="handle"/>
            </div>
            <input class="is-5-columns input" v-model="option.value"/>
            <div class="is-5-columns is-flex align-items-center " v-if="!currentField.linked_field" >
              <span
                  v-if="option.default === true"
                  @click="removeDefault()"
                  class="component-tag parent p-025 pr-075 m-0 has-text-weight-semibold">
                <icon icon="check-circle"/>
                Default Value
              </span>
              <span
                  v-else
                  @click="setAsDefaultValue(index)"
                  class="component-tag m-0 p-025 pr-075 has-background-white has-text-grey  has-text-weight-semibold">
                <icon class="has-text-grey-lighter" icon="check-circle"/>
                Set As Default
              </span>
            </div>
            <div class="is-5-columns" v-else >
              <data-selector classes="is-fullwidth"
                             required
                             class="is-full"
                             :items="linkedCustomFieldValues"
                             hint="This predefined value will only be available when the linked field has been assigned the selected option"
                             v-model="option.linked_value">
                <action-button
                    class=""
                    v-if="option.linked_value"
                    slot="right"
                    @click="option.linked_value = null">
                  <icon icon="times"/>
                </action-button>
              </data-selector>
            </div>
            <div class="is-flex align-items-center justify-end">
              <icon @click="removeValue(index)" icon="times-circle" class="hover-highlight-danger has-text-grey"/>
            </div>
          </div>
        </div>
    </draggable>
    <div v-if="hasOptions" class="column mt-1 is-3">
      <switch-input :value="currentField.require_predefined_value"
                    :disabled="isParent"
                    hint="When active, only options from the predefined values can be selected for assets with this custom field."
                    @input="toggleRequired">
        Only Allow Predefined Values
      </switch-input>
    </div>
    <footer class="buttons is-right mt-2">
      <action-button style="border: 0.5px #ccffc8 solid" @click="savePredefinedValues" :working="working" left-icon="save" class="rounded-sml is-inverted is-success">
        Save Predefined Values
      </action-button>
      <action-button style="border: 0.5px #ffc8da solid" @click="shouldClose" :working="working" left-icon="times" class="rounded-sml is-inverted is-danger">
        Close
      </action-button>
    </footer>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import DefaultGridIcon from "@/components/ui/icons/DefaultGridIcon.vue";

export default {
  components: {
    DefaultGridIcon,
    draggable,
  },
  data: () => ({
    working: false,
    options: [],
    option: {
      value: '',
      default: false,
      linked_value: '',
    },
    saved: false,
  }),

  mounted() {
    this.setOptions()
  },

  methods: {
    savePredefinedValues() {
      this.$store.commit('assetType/setPredefinedValue', { id: this.currentField.field_id, options: this.options })
      this.clearInvalidParents()
      this.saved = true
      this.shouldClose()
    },
    addValue() {
      if (this.option.value?.length === 0 || this.option.value === '') return

      if (this.optionExists(this.option)) {
        this.$toast.warning('You have already added this option')
        return
      }

      this.options.push(this.option)
      this.resetOption()
    },
    removeValue(index) {
      this.options.splice(index, 1)
    },
    setAsDefaultValue(index) {
      this.removeDefault()
      this.options[index].default = true
    },
    removeDefault() {
      this.options.forEach(option => option.default = false)
    },
    optionExists(option) {
      return this.options.find(item => item.value.toLowerCase() === option.value.toLowerCase())
    },
    setOptions() {
      if (!this.currentField?.options?.length) {
        this.options = []
        return
      }
      this.options = Array.from(this.currentField?.options)
    },
    async confirmLeave() {
      return this.$confirm({
        title: "Warning",
        message: `Leave without saving?`,
        confirmText: "Close",
        cancelText: "Continue",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      }).catch(() => this.$whoops())
    },
    async shouldClose() {
      if (!this.saved) {
        if (await this.confirmLeave()) {
          this.$close()
        }
      } else {
        this.$close()
      }
    },
    resetOption() {
      this.option = {
        value: '',
        default: false,
        linked_value: ''
      }
    },
    updateField(property, value) {
      this.$store.commit('assetType/updateField', {
        fieldId: this.currentField.field_id,
        property,
        value
      });
      this.$emit('changed')
    },
    toggleRequired(){
      this.currentField.require_predefined_value = !this.currentField.require_predefined_value
      this.updateField('require_predefined_value', this.currentField.require_predefined_value)
    },
    clearInvalidParents(){
      this.assetType.custom_fields.forEach(field => {
        if (field.linked_field) {
          const parentField = this.assetType.custom_fields.find(parent => parent.field_id === field.linked_field);

          if (!parentField || !parentField.options || parentField.options.length < 1) {
            this.$store.commit('assetType/deLinkFromInvalidParent', field.field_id)
          }
        }
      })
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    currentField(){
      return this.assetType.selected_custom_field
    },
    linkedCustomField(){
      const linkedFieldId = this.currentField.linked_field
      return this.assetType.custom_fields?.find(field => field.field_id === linkedFieldId)
    },
    linkedCustomFieldValues(){
      return this.linkedCustomField?.options?.map(option => {
        return { 'value': option.value, 'label': option.value}
      })
    },
    hasOptions(){
      return this.options.length > 0
    },
    isParent(){
      return this.assetType.custom_fields?.filter(field => field.linked_field === this.currentField.field_id).length > 0
    },
    missingParentOptions(){
      if(!this.currentField.linked_field) return true
      const optionValues = this.options?.map(option => option.linked_value)
      return this.linkedCustomField?.options?.filter(option => optionValues?.includes(option.value) === false)
    }
  },
}
</script>