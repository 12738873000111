<template>
  <base-modal wide form :title="`${Naming.Job} Closing Comments`" :close-button="false" :overlay-closes="false">
    <loader v-bind="{ loading }">
      <div class="grid has-2-columns gap-1" v-for="(customField, index) in job.type?.closing_custom_fields"
           :key="index">

        <meta-field :field="customField" @updatedInput="validateJobCanBeClosed"/>
      </div>

      <footer class="buttons is-right mt-2">
        <action-button
            :disabled="!jobCanBeClosed"
            @click="closeJob"
            :working="closing"
            :data-tooltip="tooltipText"
            left-icon="clock"
            class="is-rounded is-warning has-tooltip-multiline">
          Close
        </action-button>
        <action-button
            @click="cancelClosing"
            :working="cancelClose"
            left-icon="times"
            class="is-rounded is-danger">
          Cancel
        </action-button>
      </footer>
    </loader>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import textLengthValidation from '@/utils/validationUtils'
import MetaField from "@/views/job/partials/MetaField.vue";

export default {
  components: {MetaField},

  data: () => ({
    working: false,
    closing: false,
    opening: false,
    cancelClose: false,
    loading: false,
    jobCanBeClosed: false,
  }),

  mounted() {
    this.validateJobCanBeClosed()
  },

  methods: {
    async cancelClosing() {
      this.cancelClose = true
      this.$close({reload: true, closing_comments: false})
      this.cancelClose = false
    },
    validateJobCanBeClosed() {
      this.jobCanBeClosed = !!(this.hasAnsweredAllRequiredQuestions() && this.canCloseJob);
    },
    async closeJob() {
      try {
        this.closing = true

        const closingPayload = {
          jobId: this.job.id,
          answers: this.job.answers
        }

        if (this.job.answers) {
          await this.$store.dispatch('job/saveClosingComments', closingPayload)
        }

        await this.$store.dispatch('job/closeJob', this.job.id)
        this.$toast.success(this.Convert(this.$lang.job.closed))
        this.$close(true)
      } catch (error) {
        this.$whoops()
      } finally {
        this.closing = false
      }
    },
    hasAnsweredAllRequiredQuestions() {
      let response = true
      if (this.job.type?.closing_custom_fields) {
        this.job.type.closing_custom_fields.forEach(field => {
          if (field.is_required && this.isNotAnswered(field)) {
            response = false
          }
        })
      }
      return response
    },
    isNotAnswered(field) {
      if(!this.job.answers || this.job.answers.length === 0) {
        return true
      }

      let answer = this.job.answers[field.label] ?? ''
      return answer.trim().length === 0
    },
    validateInput(fieldValue, fieldLength, fieldName) {
      return textLengthValidation(fieldValue, fieldLength, fieldName)
    },
  },

  computed: {
    ...mapGetters('job', [
      'job'
    ]),
    canCloseJob() {
      return !this.job.completed_at && this.$root.hasAbility('close-jobs', 'Billow\\Models\\Job')
    },
    tooltipText() {
      return !this.jobCanBeClosed ? `${this.Naming.Job} Closing comments with * require answers.` : null
    }
  },
}
</script>
