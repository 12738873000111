<template>
  <base-modal form :title="`${Naming.Job} Summary Report Options`">
    <div class="is-flex is-align-items-center is-justify-content-space-between">
      <h3 class="has-text-weight-bold is-size-5">
        Configure {{ Naming.Job }} Details:
      </h3>
      <action-button
          @click="resetLocalStorageOptions"
          :working="working"
          left-icon="reply"
          class="is-rounded is-primary is-small has-text-weight-semibold">
        Reset Options
      </action-button>
    </div>
    <div class="grid has-2-columns">
      <div class="column">
        <checkbox-input
            :value="options.show_job_signatures"
            @input="toggleJobSignatures"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Include ${Naming.Job} Signatures`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.show_job_closing_answers"
            @input="toggleJobClosingAnswers"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Include ${Naming.Job} Closing Answers`"/>
      </div>

      <div class="column">
        <checkbox-input
            :value="options.show_pass_or_percentage"
            @input="toggleShowPassFailOrPercentage"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Show ${Naming.Job} Pass/Fail`"/>
      </div>
      <div class="column">
          <checkbox-input
              :value="options.show_company_logo"
              @input="toggleShowCompanyLogos"
              classes="is-circle is-primary"
              :label="false"
              :inner-label="`Show ${Naming.Company} Logos`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.show_company_details"
            @input="toggleShowCompanyDetails"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Show ${Naming.Company} Details`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.show_client_address"
            @input="toggleClientAddress"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Include ${Naming.Client} Address`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.show_asset_metadata"
            @input="toggleAssetMetaData"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Include ${Naming.Asset} Metadata`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.show_inspection_closing_comment"
            @input="toggleInspectionClosingComment"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Include ${Naming.Inspection} Closing Comment`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.show_site_address"
            @input="toggleSiteAddress"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Include the ${Naming.Site} Address`"/>
      </div>
      <div class="column">
        <checkbox-input
            :value="options.sort_by_zones"
            @input="toggleSortByZone"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Sort the ${Naming.Inspections} by ${Naming.Zone}`"/>
      </div>
    </div>
    <div class="grid has-1-columns gap-1 pt-1">
      <h3 class="has-text-weight-bold is-size-5">Configure {{ Naming.Inspection }} Answers</h3>
      <div class="column">
        <checkbox-input
            :value="showAllAssetTypes"
            @input="toggleAllAssetTypes"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Show Answers For All ${Naming.AssetTypes}`"/>
      </div>
    </div>

    <div class="grid has-1-columns gap-1 pt-1">
      <data-selector
          multiple
          searchable
          delete-buttons
          tag-class="is-info"
          :value="options.asset_types"
          :items="assetTypes"
          label-key="name"
          value-key="id"
          @input="updateAssetTypes"
      >Only Show Answers For These {{ Naming.AssetTypes }}:
      </data-selector
      >
    </div>

    <div class="grid has-2-columns gap-1 pt-2">
      <div class="column">
        <checkbox-input
            v-if="showAnswersToggle"
            :value="options.show_answers"
            @input="toggleShowAnswers"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Show Answers For ${Naming.Inspections}`"/>
      </div>
      <div class="column">
        <checkbox-input
            v-if="showAnswerCommentsToggle"
            :value="options.show_answer_comments"
            @input="toggleShowAnswerComments"
            classes="is-circle is-primary"
            :label="false"
            :inner-label="`Show Answer Comments for ${Naming.Inspections}`"/>
      </div>
    </div>

    <footer class="buttons is-right mt-2">

      <action-button
          @click="downloadJobSummaryReport"
          :working="working"
          left-icon="download"
          class="is-rounded is-success">
        Download
      </action-button>
    </footer>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {job as backend} from "@/api";
import download from "downloadjs";

export default {

  data: () => ({
    working: false,
    options: null,
    showAllAssetTypes: false,
    assetTypes: [],
    thresholdToShowAddAllAssetTypesToggle: 3,
  }),

  async created() {
    await this.loadLocalStorageOptions()
    await this.getAndSetAssetTypesByJobId()
  },


  methods: {

    toggleAllAssetTypes() {
      this.showAllAssetTypes = !this.showAllAssetTypes
      this.setAllAssetTypesBasedOnFlag()
    },

    toggleAssetMetaData() {
      this.options.show_asset_metadata = !this.options.show_asset_metadata
    },

    toggleShowAnswers() {
      this.options.show_answers = !this.options.show_answers
    },

    toggleShowAnswerComments() {
      this.options.show_answer_comments = !this.options.show_answer_comments
    },

    toggleJobSignatures() {
      this.options.show_job_signatures = !this.options.show_job_signatures
    },

    toggleJobClosingAnswers() {
      this.options.show_job_closing_answers = !this.options.show_job_closing_answers
    },

    toggleInspectionClosingComment() {
      this.options.show_inspection_closing_comment = !this.options.show_inspection_closing_comment
    },

    toggleClientAddress() {
      this.options.show_client_address = !this.options.show_client_address
    },

    toggleSiteAddress() {
      this.options.show_site_address = !this.options.show_site_address
    },

    toggleShowPassFailOrPercentage() {
      this.options.show_pass_or_percentage = !this.options.show_pass_or_percentage
    },

    toggleShowCompanyLogos() {
      this.options.show_company_logo = !this.options.show_company_logo
    },

    toggleShowCompanyDetails() {
      this.options.show_company_details = !this.options.show_company_details
    },

    toggleSortByZone() {
      this.options.sort_by_zones = !this.options.sort_by_zones
    },

    updateAssetTypes(payload) {
      this.options.asset_types = payload
      this.shouldToggleAnswerTogglesToFalse()
      this.setShowAllAssetTypes()
    },

    shouldToggleAnswerTogglesToFalse() {
      if (this.options.asset_types.length === 0) {

        this.options.show_answer_comments = false
        this.options.show_answers = false
      }
    },

    setShowAllAssetTypes() {
      if (this.options.asset_types.length < this.assetTypes.length) {

        this.showAllAssetTypes = false

        this.shouldToggleAnswerTogglesToFalse()
      }
    },

    downloadJobSummaryReport() {
      this.saveOptionsToLocalStorage()
      const payload = {
        jobId: this.job.id,
        options: this.options
      }

      this.working = true

      backend.downloadSummaryReport(payload, ({data}) => {
        download(data, `${this.Convert('job')}-summary-report-#${this.job.number}-${this.job.site.name}.pdf`, 'application/pdf')
        this.$close()
      }, error => {
        this.$whoops()
      }).finally(() => this.working = false)
    },

    async getAndSetAssetTypesByJobId() {
      try {
        this.assetTypes = await this.$store.dispatch('job/fetchAssetTypesByJob', this.job.id)
        this.setAllAssetTypesBasedOnFlag()
      } catch (error) {
        this.$whoops()
      }
    },
    saveOptionsToLocalStorage() {
      const userId = this.user.id
      let optionsToSave = { ...this.options }
      delete optionsToSave.asset_types

      const data = {
        options: optionsToSave,
        showAllAssetTypes: this.showAllAssetTypes,
      }

      localStorage.setItem(`job_summary_options_${userId}`, JSON.stringify(data))
    },
    async loadLocalStorageOptions() {
      const userId = this.user.id
      const savedData = localStorage.getItem(`job_summary_options_${userId}`)
      this.options = this.getDefaultOptions()
      if (savedData) {
        const parsedData = JSON.parse(savedData)
        this.options = { ...this.options, ...parsedData.options }
        this.showAllAssetTypes = parsedData.showAllAssetTypes ?? false
        this.$toast.info("Previous configuration for job summary report has been applied.")
      }
    },
    resetLocalStorageOptions() {
      const userId = this.user.id
      localStorage.removeItem(`job_summary_options_${userId}`)
      this.options = this.getDefaultOptions()
      this.showAllAssetTypes = false
    },
    setAllAssetTypesBasedOnFlag() {
      if (this.showAllAssetTypes) {
        return this.options.asset_types = [...this.assetTypes]
      }
      return this.options.asset_types = []
    },
    getDefaultOptions() {
      return {
        show_asset_metadata: false,
        show_job_closing_answers: false,
        show_inspection_closing_comment: false,
        show_job_signatures: false,
        show_client_address: false,
        show_site_address: false,
        sort_by_zones: false,
        show_pass_or_percentage: false,
        show_company_logo: false,
        show_company_details: false,
        asset_types: [],
        show_answers: false,
        show_answer_comments: false,
      }
    }
  },

  computed: {
    ...mapGetters('job', [
      'job'
    ]),
    ...mapGetters('inspection', [
      'inspections'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),

    showSelectAllAssetTypes() {
      return this.assetTypes.length > this.thresholdToShowAddAllAssetTypesToggle
    },

    showAnswersToggle() {
      return this.options.asset_types?.length > 0
    },

    showAnswerCommentsToggle() {
      return this.options.show_answers && this.options.asset_types?.length
    },

    jobPassFailOrPercentage() {
      return this.options.show_pass_or_percentage ? "Show Job Pass/Fail" : "Show Job Percentage"
    },
  },
}
</script>
