<template>
  <base-modal wide form :title="`${Naming.Job} Tools`">
    <loader v-bind="{ loading }">
      <div class="grid has-2-columns gap-1">
        <!-- Job Type -->
        <server-data-selector
            searchable
            required
            :items="computedJobTypeList"
            :error="$root.errors?.job_type_id"
            :value="job.job_type_id"
            @input="jobType"
            value-key="id"
            label-key="description"
            :on-type="searchJobTypes">
          {{ Naming.JobType }}
        </server-data-selector>

        <!-- Technician -->
        <server-data-selector
            searchable
            required
            :items="computedUserList"
            :error="$root.errors?.technician_id"
            :value="job.technician_id"
            :description="technicianDescription"
            :descriptionClass="technicianDangerClass"
            @input="technician"
            value-key="id"
            label-key="full_name"
            :on-type="searchTechnician">
          Change {{ Naming.Technician }}
          <div slot="item" slot-scope="{ item }" class="is-flex align-center">
            <avatar
                :size="28"
                :path="item.logo_url"
                :name="item.name"/>
            <div class="is-flex flex-column ml-1">
              <span class="has-text-weight-bold">{{ item.full_name }}</span>
            </div>
          </div>
        </server-data-selector>

        <div class="columns">
          <div class="column">
            <!-- Start Date -->
            <date-picker required
                         alt-date-format="l, j F Y"
                         :value="job.start_date"
                         @input="startDate"
                         :error="$root.errors?.start_date">
              Start Date
            </date-picker>
          </div>
          <div class="column">
            <date-picker
                required
                time
                no-calendar
                @input="startTime"
                :value="job.start_time"
                :error="$root.errors?.start_time">
              Start Time
            </date-picker>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <!-- End Date -->
            <date-picker
                required
                alt-date-format="l, j F Y"
                :min-date="job.start_date"
                :value="job.end_date"
                @input="endDate"
                :error="$root.errors?.end_date">
              End Date
            </date-picker>
          </div>
          <div class="column">
            <date-picker
                required
                time
                no-calendar
                @input="endTime"
                :value="job.end_time"
                :error="$root.errors?.end_time">
              End Time
            </date-picker>
          </div>
        </div>

        <server-data-selector
            class="is-1-columns"
            required
            searchable
            :value="job.site_id"
            :error="$root.errors?.site_id"
            @input="updateSite"
            :items="computedSiteList"
            prompt-label="Select a site…"
            label-key="name"
            value-key="id"
            :hint="`Changing the ${Naming.Site.toLowerCase()} will detach any ${Naming.Zones.toLowerCase()} currently on the ${Naming.Job.toLowerCase()}.`"
            :on-type="searchSite">
          {{ Naming.Site }}
          <div slot="item" slot-scope="{ item }" class="is-flex align-center">
            <avatar
                :size="28"
                :path="item.logo_url"
                :name="item.name"/>
            <div class="is-flex flex-column ml-1">
              <span class="has-text-weight-bold">{{ item.name }}</span>
            </div>
          </div>
        </server-data-selector>

        <!-- Zones -->
        <data-selector
            required
            multiple
            searchable
            delete-buttons
            class="is-1-columns"
            tag-class="is-primary"
            :items="job.site.zones"
            :error="$root.errors?.zones"
            :value="job.zones"
            :empty-text="`No ${Naming.Zones.toLowerCase()} to display.`"
            @input="updateZones"
            value-key="id"
            label-key="name">
          {{ Naming.Zones }}
        </data-selector>

        <text-input class="is-1-columns" :error="validateInput(job.reference, 255, 'Reference')" :value="job.reference"
                    @input="reference">Reference #
        </text-input>

        <text-area
            class="is-2-columns"
            :value="job.special_instructions" @input="specialInstructions">
          Special Instructions
        </text-area>

      </div>

      <footer class="buttons is-right mt-2">
        <submit-button
            class="is-rounded is-success has-text-weight-bold"
            :disabled="job.completed_at || closing || opening || working || job.reference?.length>255"
            :working="working"
            @submit="store"
            left-icon="check">
          Save {{ Naming.Job }}
        </submit-button>
        <action-button
            v-if="showCloseButton"
            :data-tooltip="!canCloseJob ? tooltipText : null"
            :class="custom-multiline-tooltip"
            :disabled="!canCloseJob"
            @click="closeJob"
            :working="closing"
            left-icon="clock"
            class="is-rounded is-warning">
          Close {{ Naming.Job }}
        </action-button>
        <action-button
            v-if="job.completed_at"
            @click="reopenJob"
            :working="opening"
            left-icon="clock"
            class="is-rounded is-warning">
          Open {{ Naming.Job }}
        </action-button>
        <action-button
            v-if="$root.hasAbility('delete-jobs', 'Billow\\Models\\Job')"
            @click="deleteJob"
            :disabled="closing || opening || working"
            left-icon="trash"
            class="is-rounded is-danger">
          Delete {{ Naming.Job }}
        </action-button>

      </footer>
    </loader>
  </base-modal>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Search from '@/utils/search'
import textLengthValidation from '@/utils/validationUtils'

export default {

  data: () => ({
    working: false,
    closing: false,
    opening: false,
    loading: true
  }),

  created() {
    this.clearAllStores()
    Promise.all([
      Search.searchByUri('', 'jobType/searchActiveJobTypes', this.$store, 0),
      Search.searchByUri('', 'site/searchSite', this.$store, 0),
      Search.searchByUri('', 'user/searchTechnician', this.$store, 0)
    ]).then(() => {
      this.loading = false
    })
    this.startTime(moment(this.job.start_date).format("HH:mm"))
    this.endTime(moment(this.job.end_date).format("HH:mm"))
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('site', [
      'setSiteList',
    ]),
    ...mapMutations('jobType', [
      'setJobTypeList',
    ]),
    ...mapMutations('job', [
      'jobType',
      'technician',
      'startDate',
      'endDate',
      'updateZones',
      'specialInstructions',
      'reference',
      'site',
      'startTime',
      'endTime',
    ]),
    store() {
      this.working = true
      this.$store.dispatch('job/save').then(() => {
        this.$toast.success(this.Convert(this.$lang.job.saved))
        this.working = false
        this.$close({reload: true, closing_comments: false})
      }).catch(error => {
        this.working = false
        console.log(error)
        this.$whoops()
      })
    },
    async closeJob() {
      this.closing = true
      if (this.job.type?.closing_custom_fields?.length > 0) {
        this.$close({reload: false, closing_comments: true})
        return
      }
      this.$store.dispatch('job/closeJob', this.job.id).then(() => {
        this.$toast.success(this.Convert(this.$lang.job.closed))
        this.closing = false
        this.$close({reload: true, closing_comments: false})
      }).catch(error => {
        this.closing = false
        this.$toast.warning(error.response.data.message ?? 'An error occurred.')
      })
    },
    reopenJob() {
      this.opening = true
      this.$store.dispatch('job/reopenJob', this.job.id).then(() => {
        this.$toast.success(this.Convert(this.$lang.job.opened))
        this.opening = false
        this.$close({reload: true, closing_comments: false})
      }).catch(error => {
        this.opening = false
        this.$whoops()
      })
    },async deleteJob() {
      const { title, message, confirmText } = this.$lang.job.confirmDelete;
      const convertedTitle = await this.Convert(title);
      const convertedMessage = await this.Convert(message);
      const convertedConfirmText = await this.Convert(confirmText);

      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger',
      })) {
        this.working = true;
        this.$store.dispatch('job/deleteJob', this.job.id).then(() => {
          this.$toast.success(this.Convert(this.$lang.job.deleted));
          this.$router.back();
          this.$close({ reload: false, closing_comments: false });
        }).catch(error => {
          this.working = false;
          console.error(error);
          this.$whoops();
        });
      }
    },
    updateSite(site) {
      if (this.loading) return
      this.site(site)
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    searchTechnician(text) {
      Search.debouncedSearchByUri(text, 'user/searchTechnician', this.$store)
    },
    searchJobTypes(text) {
      Search.debouncedSearchByUri(text, 'jobType/searchActiveJobTypes', this.$store)
    },
    clearTechnicianFilter() {
      this.setUserList([])
    },
    clearJobTypeFilter() {
      this.setJobTypeList([])
    },
    clearSiteFilter() {
      this.setSiteList([])
    },
    clearAllStores() {
      this.setUserList([])
      this.setSiteList([])
      this.setJobTypeList([])
    },
    validateInput(fieldValue, fieldLength, fieldName) {
      return textLengthValidation(fieldValue, fieldLength, fieldName)
    },
  },

  computed: {
    ...mapGetters('inspection', [
      'inspections'
    ]),
    ...mapGetters('job', [
      'job'
    ]),
    ...mapGetters('jobType', [
      'jobTypeList'
    ]),
    ...mapGetters('user', [
      'userList'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    computedJobTypeList() {
      const arr = this.jobTypeList.filter(item => item.id !== this.job.type.id)
      return [this.job.type, ...arr]
    },
    computedSiteList() {
      const arr = this.siteList.filter(item => item.id !== this.job.site.id)
      return [this.job.site, ...arr]
    },
    computedUserList() {
      const arr = this.userList.filter(item => item.id !== this.job.technician.id)
      return [this.job.technician, ...arr]
    },
    hasInspections() {
      return this.inspections?.data?.length > 0
    },
    hasNoInProgressInspections() {
      return !this.inspections?.data?.some(inspection => inspection.completed_at == null)
    },
    hasTechnicianSignature() {
      if(this.job.type?.requires_technician_signature === true) {
        return this.job.technician_signature
      }
      return true
    },
    hasCustomerSignature() {
      if(this.job.type?.requires_signature === true) {
        return this.job.signature_image
      }
      return true
    },
    showCloseButton() {
      return this.$root.hasAbility('close-jobs', 'Billow\\Models\\Job') && !this.job.completed_at
    },
    canCloseJob() {
      return !this.job.completed_at && this.hasCustomerSignature && this.hasTechnicianSignature && this.hasInspections && this.hasNoInProgressInspections
    },
    technicianDescription(){
      if(this.job.type.requires_technician_signature) {
        return `Changing the ${this.Naming.Technician.toLowerCase()} will only allow the new ${this.Naming.Technician.toLowerCase()}\'s signature to be captured, and all ${this.Naming.Inspections.toLowerCase()} done by the previous ${this.Naming.Technician.toLowerCase()} will not be signed.`
      }
      return `Changing the ${this.Naming.Technician.toLowerCase()} will automatically remove this ${this.Naming.Job.toLowerCase()} from the current ${this.Naming.Technician.toLowerCase()}.`
    },
    technicianDangerClass() {
      if(this.job.type.requires_technician_signature) {
        return 'has-text-danger'
      }
      return ''
    },
    tooltipText() {
      const reasons = []
      if (!this.hasCustomerSignature) reasons.push('Requires customer signature.')
      if (!this.hasTechnicianSignature) reasons.push(`Requires ${this.Naming.Technician.toLowerCase()} signature.`)
      if (!this.hasInspections) reasons.push(`Requires ${this.Naming.Inspections.toLowerCase()} but does not have any.`)
      if (!this.hasNoInProgressInspections) reasons.push(`Has in-progress ${this.Naming.Inspections.toLowerCase()}.`)

      return reasons.join('\n')
    },

  },

  beforeRouteLeave(to, from, next) {
    this.clearAllStores()
    next()
  }

}
</script>
